<template>
    <footer id="footer" class="footer pb-2">

    <div class="footer-content position-relative">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-info">
              <h3>SILI-<span class="text-color">BTP</span> SAS<span style="color:var(--color-primary)">.</span></h3>
              <p>Notre adresss</p><br/>
              <p class="adress">
               Abidjan,<br><br>
                <strong>Tél:</strong> +225 05-76-35-48-48<br>
                <strong>Email:</strong> info@sili-btp.com<br>
                
              </p>
              <div class="social-links d-flex mt-3">
                <a href="#" class="d-flex align-items-center justify-content-center"><i class="bi bi-facebook" style=""></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center d-none"><i class="bi bi-twitter"></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center d-none"><i class="bi bi-instagram"></i></a>
                <a href="#" class="d-flex align-items-center justify-content-center d-none"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div><!-- End footer info column-->

          <div class="col-lg-3 col-md-4 footer-links">
            <h4 style="font-size:1.2em;font-weight:bold">Liens utitles</h4>
            <ul>
              <li><router-link to="/">accueil</router-link></li>
              <li><router-link to="/presentations">Qui sommes nous ?</router-link></li>
              <li><router-link to="/services">Nos services</router-link></li>
              <li><router-link to="/projets">projet</router-link></li>
            </ul>
          </div>
          <!-- End footer links column-->
           <div class="col-lg-3 col-md-4 footer-links">
            <h4 style="font-size:1.2em;font-weight:bold">Contactez-nous</h4>
            <ul>
              <li><strong style="font-weight: bold;">Tél:</strong><br/>
                <ul style="margin: 5px !important;position: relative;left: 28px;font-size: 1.3em;">
                 <li class="" v-for="(adress, index) in metaInfo.contacts" :key="index">{{ adress}}</li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 footer-links">
             <h4 style="font-size:1.2em;font-weight:bold"></h4>
            <ul>
              <li><strong style="font-weight: bold;">Email:</strong><br/>
                <ul style="margin: 5px !important;position: relative;left: 28px;font-size: 1.3em;">
                 <li class="" v-for="(email, index) in metaInfo.emails" :key="index">{{ email}}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-legal text-center position-relative">
      <div class="container">
        <div class="copyright">
         <strong  style="font-size:2em">SILI &<span class="text-color"> BTP </span>SAS</strong> <strong><span class="text-color">.</span></strong>  <br/>
          &copy; Copyright 2023
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
    name:"BottomFooter",
    components:{
    },
    computed:{
      metaInfo(){
        return  this.$store.getters.getSiteInfo
      }
    },
}
</script>

<style scoped>
.copyright{
  font-size: 1.2em !important;
}
.footer-info h3{
  font-size: 2em !important;
}
li >strong{
  color:var(--color-primary);
  font-weight: bold;
  font-size: 1.5em;
}
.text-color{
  color:var(--color-primary);
}
</style>
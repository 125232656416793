<template>
   <section id="get-started" class="get-started section-bg">
      <div class="container">

        <div class="row justify-content-between gy-4">

          <div class="col-lg-6 d-flex align-items-center" data-aos="fade-up">
            <div class="row m-0 p-0">
            <div class="content">
              <h3 data-aos="zoom">Mieux nous connaitre.</h3>
              <p data-aos="fade-down"> <strong class="color-1 text-1"> SILI et BTP SAS </strong> <span> (Société par Action Simplifié ) c'est une société franco-ivoirienne basée en <strong class="color-1"> Côte d'ivoire</strong>. </span></p>
              <p data-aos="fade-up"><span class="text-1">  Elle est composée d'une équipe qui a le savoir faire , cette expertise acquise au fil des années d'expérience , elle a la capacité de réaliser des designs architecturaux les plus complexe . Notre priorité est la satisfaction de nos clients même les plus exigeants , 
                l'innovation apparaît  au croisement des disciplines .</span> </p>
            </div>
            </div>
          </div>

          <div class="col-lg-5 d-none d-md-block" data-aos="fade">
            <div class="img-fluide">
                <div class="card bg-dark text-white border-0 card-bg-1 shadow" data-aos="fade-left" data-aos-delay="100">
                <img :src="startImgUrl" class="card-img shadow" alt="start img" data-aos="zoom-in" data-aos-delay="200">
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name:"GetStarted",
    data(){
        return {
            startImgUrl: require("@/assets/img/logo.jpg")
        }
    }
}
</script>

<style lang="css" scoped>
img{
    position: relative;
    right: 10px;
    top: 18px;
    height: 450px;
    width: 30.5rem;
}

.color-1{
    color: chocolate !important;
}
p span{
    font-size:2.0em;
    
}

.text-1{
    font-size:1.8em;
}
</style>
<template>
  <div class="col-xl-4 col-lg-6 col-md-6 projet-item" @mouseenter="activeBox" @mouseleave="desactiveBox" data-aos="fade-up" :data-aos-delay="delay">
    <div class="position-relative projet-box rounded mb-5 p-0">
        <img class="img-fluid w-100 rounded" :src="data.cover" alt="" style="height: 342px !important;">
        <router-link class="projet-title p-4 shadow-sm rounded" :to="{ name: 'projetDetail', params: { projet_id: id }}" @click.prevent="goProjetDetail()">
            <p class="h4 text-uppercase">{{data.title}}</p>
             <span class="text-body"><i class="fa fa-map-marker-alt text-primary me-2"></i>{{ data.country}}, {{data.city}}</span>
        </router-link>
        <router-link :class="projetBtn" class="rounded d-none" :to="{ name: 'projetDetail', params: { projet_id: id }}">
         <span :class="btnSpan">
            <i class="bi bi-plus"></i> VOIR
             </span>
        </router-link>
    </div>
</div>
</template>

<script>
export default {
   name:"ProjetCard",
   data:function(){
    return {
        projet:{
            title:"projet title",
            country:"Country",
            city:"city",
            cover:"http://127.0.0.1:5501/UpConstruction/UpConstruction/assets/img/projects/construction-2.jpg",
        },
        projetBtn:{projetBtn:false},
        btnSpan:{btnSpan:false},
    }
   },
   props:['item', "id", "delay"],
    computed:{
        data(){
            return {  
            title:this.item?.title,
            country:this.item?.country,
            city:this.item?.city,
            cover:this.item?.images[0],
            }

        }
    },
   methods:{
    goProjetDetail(){
        this.$router.push({ name: 'projetDetail', params: { projet_id: this.id } })
    },
    activeBox(){
        this.projetBtn.projetBtn= !this.projetBtn.projetBtn
        this.btnSpan.btnSpan = !this.btnSpan.btnSpan
    },
    desactiveBox(){
        this.projetBtn.projetBtn= !this.projetBtn.projetBtn
        this.btnSpan.btnSpan = !this.btnSpan.btnSpan
    }
   }
}
</script>

<style lang='scss' scoped>
.projet-title{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 120px;
    padding: 0 30px;
    right: 30px;
    left: 30px;
    bottom: -80px;
    font-size: medium;
    background: #FFFFFF;
    z-index: 3;
    transition: all 0.2s ease-in-out;
}

.projet-box{
 &:hover{
  .projet-title{
  bottom: -40px;
}
    .projet-box img {
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12) !important;
    }
  }
}

.projetBtn{
display: block !important;
width: 100%;
height: 100%;
position: absolute;
top: 0rem;
text-align: center;
//font-size: 2em;
font-weight: bold;
background: rgba(0,0,0,0.5);
}

.btnSpan{
    font-size: 2em ;
    color: white;
    position: absolute;
    top: 8.2rem;
    left: 0;
    right: 0;
    font-weight: bold;
    text-align: center;
    transition: all 0.1s ease-in;
}

.mb-5{
    margin-bottom: 4rem !important;
}

</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap"


import "./assets/vendor/bootstrap/css/bootstrap.min.css" 
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css" 
import "./assets/vendor/fontawesome-free/css/all.min.css" 
import "./assets/vendor/aos/aos.css" 
import "./assets/vendor/glightbox/css/glightbox.min.css" 
import "./assets/vendor/swiper/swiper-bundle.min.css" 

import "./assets/css/main.css"

import AOS from 'aos'
import 'aos/dist/aos.css'
//import Isotope from 'public/vendor/isotope-layout/isotope.pkgd'
import Swiper from 'swiper/bundle';

require("@/assets/js/custom.swiper")
require('@/assets/js/main')
  /**
   * Init swiper slider with 1 slide at once in desktop view
   */
  const Swiper1 = new Swiper('.slides-1', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  /**
   * Init swiper slider with 2 slides at once in desktop view
   */
  const Swiper2 = new Swiper('.slides-2', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      1200: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  });



createApp(App).use(store).use(router)
    .use(AOS.init())
    .use(Swiper1)
    .use(Swiper2)
    .mount('#app')

const serveurBaseUri = 'https://api.sili-btp.com/'
// const serveurBaseUri = 'http://localhost:3031/'


export const serviceDataMock = [
    {
        "title": "Construction de bâtiments",
        "long_title":"Construction, rénovation de bâtiments",
        "icon":"fa-solid fa-mountain-city",
        "vector":"",
        "description": "Nous construisons des bâtiments avec nos experts, qui travaille en collaboations depuis planification, la coordination et la gestion de diverses étapes du processus de construction, telles que la conception, la préparation du terrain, la construction de la structure, l'installation des systèmes électriques, de plomberie et de climatisation, ainsi que la finition intérieure et extérieure.Le tout conformement aux normes de sécurité et de réglementation en vigueur. Les projets sont achevés dans les délais impartis et dans les limites budgétaires convenues.",
        "img":serveurBaseUri+"img/services/service-2.jpeg"
    },
    {
        "title": "Direction de travaux sur chantier",
        "long_title":"",
        "icon":"fas fa-hard-hat",
        "vector":"",
        "description": "direction de travaux sur chantier d'envergure ou à forte valeur ajoutée (stades de football , hôpitaux , tours , bureaux , hôtels , villas et resorts de luxe , sites présidentiels , achat , vente , électricité générale , climatisation , plomberie , etc.)",
        "img":serveurBaseUri+"img/services/service-1.jpeg"
    },
    {
        "title": "Bureau d'étude",
        "long_title":"",
        "icon":"fas fa-pencil-ruler",
        "vector":"",
        "description": "Nous réalisons des études et prestations de conseils techniques dans le bâtiment et le genie civil.",
        "img":serveurBaseUri+"img/services/service-2.jpeg"
    },
    {
        "title": "Électricité générale",
        "long_title":"",
        "icon":"fas fa-bolt",
        "vector":"",
        "description": "Cela comprend l'installation et la maintenance de systèmes électriques dans les bâtiments, tels que les circuits de distribution électrique, l'éclairage, les prises de courant, les interrupteurs, les tableaux de distribution et les générateurs de secours.",
        "img":serveurBaseUri+"img/services/service-1.jpeg"
    },

    {
        "title": "Plomberie",
        "long_title":"",
        "icon":"fas fa-wrench",
        "vector":"",
        "description": "Cela comprend l'installation et la maintenance de systèmes de plomberie dans les bâtiments, tels que les tuyaux d'eau, les robinets, les lavabos, les douches, les baignoires, les toilettes et les systèmes de drainage.",
        "img":serveurBaseUri+"img/services/service-2.jpeg"
    }

]


export const expertDataMock =[

    // {
    //     "fullName":"Leponge Bob",
    //     "fullRole":"Operateur",
    //     "profilImg":serveurBaseUri+"img/team/team-2.jpg",
    //     "description":"petite description"
    // }
]


export const projetDataMock =[
    {

        "title": "Projet: Bureaux du Siège clientèle de la CNP ",
        "category":"construction",
        "country":"France",
        "city": "",
        "date":"-",
        "description":"",
        "images":[
        serveurBaseUri+"img/projets/bureau/bureau_cnp_09.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_01.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_02.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_04.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_05.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_06.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_07.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_08.png",
        serveurBaseUri+"img/projets/bureau/bureau_cnp_03.png",
    ]
    },

    {

        "title": "Projet:  Hôpital Corentin CELTON",
        "category":"construction",
        "country":"France",
        "city": "",
        "date":"-",
        "description":"",
        "images":[
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_01.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_02.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_03.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_04.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_05.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_06.png",
        serveurBaseUri+"img/projets/hotel_Corentin/hotel_corentin_celton_07.png",

    ]
    },
    {

        "title": "Projet: Stade Omnisport Omar BONGO ONDIMBA ",
        "category":"construction",
        "country":" GABON",
        "city": "Libreville",
        "date":"-",
        "description":"",
        "images":[
            serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_04.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_01.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_02.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_03.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_05.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_06.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_07.png",
        serveurBaseUri+"img/projets/Stade_omar/stade_omnisport_08.png",

    ]
    },
    {

        "title": "Villa de l’Amitié",
        "category":"construction",
        "country":" REPUBLIQUE DU CONGO",
        "city": "Brazzaville",
        "date":"-",
        "description":"",
        "images":[
            serveurBaseUri+"img/projets/villa_amitie/villa_02.png",
            serveurBaseUri+"img/projets/villa_amitie/villa_01.png",
            serveurBaseUri+"img/projets/villa_amitie/villa_04.png",
            serveurBaseUri+"img/projets/villa_amitie/villa_05.png",
    ]
    },
    {

        "title": "Complexe Résidentiel",
        "category":"construction",
        "country":" REPUBLIQUE DU CONGO",
        "city": "Pointe Noire",
        "date":"-",
        "description":"Complexe Résidentiel avec Commerces",
        "images":[
            serveurBaseUri+"img/projets/residence/complexe_residenciel_01.png",
            serveurBaseUri+"img/projets/residence/complexe_residenciel_02.png",
            serveurBaseUri+"img/projets/residence/complexe_residenciel_03.png",
    ]
    },
    {

        "title": "Complexe Hôtelier",
        "category":"construction",
        "country":" REPUBLIQUE DU CONGO",
        "city": "Pointe Noire",
        "date":"-",
        "description":"Complexe Hôtelier TWIGA- Pointe Noire REPUBLIQUE DU CONGO",
        "images":[
            serveurBaseUri+"img/projets/hotelier_1/complexe_01.png",
            serveurBaseUri+"img/projets/hotelier_1/complexe_02.png",
            serveurBaseUri+"img/projets/hotelier_1/complexe_03.png",
            serveurBaseUri+"img/projets/hotelier_1/complexe_04.png",
    ]
    },
    {

        "title": "Stade Omnisport de BARAKI",
        "category":"construction",
        "country":"ALGERIE",
        "city": "Alger",
        "date":"-",
        "description":"Stade Omnisport de BARAKI- Alger ALGERIE",
        "images":[
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_01.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_02.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_03.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_04.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_05.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_06.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_07.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_08.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_09.png",
            serveurBaseUri+"img/projets/Stade_Omnisport/img_stade_10.png",

    ]
    },
    {

        "title": "projet ",
        "category":"construction",
        "country":"-",
        "city": "-",
        "date":"-",
        "description":"-",
        "images":[
            serveurBaseUri+"img/projets/projet_x/projet_1.jpg",
            serveurBaseUri+"img/projets/projet_x/projet_2.jpg",
            serveurBaseUri+"img/projets/projet_x/projet_3.jpg",
            serveurBaseUri+"img/projets/projet_x/projet_4.jpg",
        ]
    },
    {

        "title": "Projet: REALISATION D'UN BATIMENT DE LOGEMENTS R+3+COMBLES ",
        "category":"construction",
        "country":"France",
        "city": "",
        "date":"-",
        "description":`- 30% DE LOGEMENTS SOCIAUX \n\t
        - 1 NIVEAUX DE SOUS/SOL \n\t
        SURFACE: 3 000m²`,
        "images":[
        serveurBaseUri+"img/projets/batiment_de_logement/batitment_de_logement_03.png",
        serveurBaseUri+"img/projets/batiment_de_logement/batitment_de_logement_01.png",
        serveurBaseUri+"img/projets/batiment_de_logement/batitment_de_logement_02.png",
        serveurBaseUri+"img/projets/batiment_de_logement/batitment_de_logement_04.png",
        serveurBaseUri+"img/projets/batiment_de_logement/batitment_de_logement_05.png",

    ]
    },
    {

        "title": "Projet: REALISATION D'UN CENTRE COMMERCIAL DANS UN BATIMENT EXISTANT ",
        "category":"construction",
        "country":"Algerie",
        "city": "",
        "date":"-",
        "description":`- PLANS DE SYNTHESE \n\t
        - PLANS DE DECORATION \n\t
        - SUIVIS DE CHANTIER \n\t
        SURFACE 8 000m²\n\t
        Inauguré en OCTOBRE 2019\n\t`,
        "images":[
        serveurBaseUri+"img/projets/centre_commercial/centre_commercial_01.png",
        serveurBaseUri+"img/projets/centre_commercial/centre_commercial_02.png",
        serveurBaseUri+"img/projets/centre_commercial/centre_commercial_03.png",
        serveurBaseUri+"img/projets/centre_commercial/centre_commercial_04.png",
        serveurBaseUri+"img/projets/centre_commercial/centre_commercial_05.png",

    ]
    },
    {

        "title": "Projet: REALISATION D'UN COMPLEXE HOTELIER - BISKRA ALGERIE",
        "category":"construction",
        "country":"Algerie",
        "city": "",
        "date":"-",
        "description":`- HOTELS 150 CHAMBRES
        - SALLE POLYVALENTE + SEMINAIRE \n
        - PISCINE + SALON DE THE \n
        - THERMES \n
        - 27 BUNGALOWS\n
        - TENNIS\n\t
        SURFACE 22 000m²\n
       `,
        "images":[
        serveurBaseUri+"img/projets/complexe_biskra/complexe_biskra_01.png",
        serveurBaseUri+"img/projets/complexe_biskra/complexe_biskra_02.png",
        serveurBaseUri+"img/projets/complexe_biskra/complexe_biskra_03.png",
        serveurBaseUri+"img/projets/complexe_biskra/complexe_biskra_04.png",
        serveurBaseUri+"img/projets/complexe_biskra/complexe_biskra_05.png",

    ]
    },
    {

        "title": "Projet: REALISATION DE 94 LOGEMENTS A CHATEAU THIERRY",
        "category":"construction",
        "country":"France",
        "city": "",
        "date":"-",
        "description":`REALISATION D'UN BATIMENT DE LOGEMENTS R+3 \n
        SURFACE: 4 800m²\n
        PERMIS DEPOSE\n
        
       `,
        "images":[
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_01.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_02.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_03.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_04.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_05.png",

    ]
    },
    {

        "title": "Projet: REALISATION DE  88 LOGEMENTS A CRECY LA CHAPELLE",
        "category":"construction",
        "country":"France",
        "city": "",
        "date":"-",
        "description":`REALISATION D'UN ENSEMBLE DE BATIMENT DE LOGEMENTS\n
        SURFACE: 5 550m²\n
        PERMIS DEPOSE 
       `,
        "images":[
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_01.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_02.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_03.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_04.png",
        serveurBaseUri+"img/projets/chateau_thierry/chateau_thierry_05.png",

    ]
    },
    {

        "title": "Projet: REALISATION D'UNHOTEL BEN AKNOUN - ALGER",
        "category":"construction",
        "country":"ALGERIRE",
        "city": "",
        "date":"-",
        "description":`- 120 CHAMBRES
        - RESTAURANT
        - SEMINAIRES
        - ETAGE SPA FITNESS
        SURFACE 10 000m²`,
        "images":[
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_01.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_02.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_03.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_04.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_05.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_06.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_07.png",
        serveurBaseUri+"img/projets/hotel_ben/hotel_ben_08.png",

    ]
    },
    {

        "title": "Projet: REALISATION DE 31 LOGEMENTS OSSATURE BOIS - MONTATAIRE",
        "category":"construction",
        "country":"France",
        "city": "MONTATAIRE",
        "date":"2013",
        "description":`MAITRE D'OUVRAGE: OISE HABITAT
        - PROJET HQE
        - 2 BATIMENTS OSSATURE BOIS R+2 SUR LONGRINES BETON
        - STRUCTURE SECHE
        - ISOLANT PROJETE SANS POLLUANTS (IPE AIR)
        - REVETEMENT EXTERIEURE (PARKING) BETON GRANULATS DRAINANT
        SURFACE: 2 300m²
        `,
        "images":[
        serveurBaseUri+"img/projets/montataire/montataire_01.png",
        serveurBaseUri+"img/projets/montataire/montataire_02.png",
        serveurBaseUri+"img/projets/montataire/montataire_03.png",
        serveurBaseUri+"img/projets/montataire/montataire_04.png",
        serveurBaseUri+"img/projets/montataire/montataire_05.png",
        serveurBaseUri+"img/projets/montataire/montataire_06.png",

    ]
    },
]


export const blogDataMock  = [
    // {
    //     "slug":"lancement-du-site-officiel-de-sili-btp-2023",
    //     "title":"Ouverture du site site web de SILI-BTP",
    //     "content": "",
    //     "date":"27 Février 2023",
    //     "img":serveurBaseUri+"img/blog/blog-1.jpg"
    // },
 
]

export const siteInfo = {
        meta:{
            title:"SILI-BTP SAS",
            short_title:"SILI-BTP",
            logo:serveurBaseUri+"img/logo.jpg",
        },
        contacts:["+225 05-76-35-48-48","+225 27-22-26-55-52","+225 05-75-75-32-99", "+33 782-892-744", "+33 638-258-283"],
        emails: [" info@sili-btp.com", "eline@sili-btp.com","president@sili-btp.com", "asselindg@sili-btp.com","izabdondga@sili-btp.com"]
}

export const sellDataMock= []

export const heroCarouelData =["img/hero-carousel/hero-carousel-01.jpg","img/hero-carousel/hero-carousel-02.jpg","img/hero-carousel/hero-carousel-03.jpg",
"img/hero-carousel/hero-carousel-04.jpg","img/hero-carousel/hero-carousel-05.jpg","img/hero-carousel/hero-carousel-06.jpg"]
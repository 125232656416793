<template>
  <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="service-item  position-relative">
              <div class="icon">
                <i :class="icon" style="font-size:4em"></i>
            </div>
            <h3>{{ titre }}</h3>
            <p>{{ shortDescription }}</p>
            <!-- <a href="/services" class="readmore stretched-link">En savoir plus <i class="bi bi-arrow-right"></i></a>    -->
        <router-link :to="{name:'services'}" class="readmore stretched-link"> En savoir plus <i class="bi bi-arrow-right"></i></router-link>
        <!-- <router-view></router-view> -->
        </div>
    </div>
</template>

<script>
export default {
    name:"ServiceCardItem",
    props:{
        titre:{
            type:String,
            required:true
        },
        icon:{
            type:String,
            required:true
        },
        description:{
            type:String,
            required:true
        }

    },
    computed:{
        shortDescription(){
            return this.description.slice(0,45)
        }
    }

}

</script>

<style>

</style>
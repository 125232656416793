<template>

     <main id="main">

    <!-- ======= Get Started Section ======= -->
      <get-started />
   <!-- End Get Started Section -->

    <!-- ======= Services Section ======= -->
      <service-section />
    <!-- End Services Section -->

        <!-- ======= Projet Section ======= -->
      <projet-secction />
    <!-- End Services Section -->

     <sell-section />

    <blog-section />
  </main>
</template>

<script>
import GetStarted from '@/components/GetStarted.vue'
import ServiceSection from '@/components/ServiceSection.vue'
import ProjetSecction from '@/components/ProjetSecction.vue'
import SellSection from '@/components/SellSection.vue'
import BlogSection from '@/components/BlogSection.vue'
export default {
    name:"HomeMain",
    components:{
      GetStarted,
      ServiceSection,
      ProjetSecction,
      SellSection,
      BlogSection
    }
}
</script>

<style>

</style>
<template>
  <div class="home">
   <top-hero />
      <home-main />
  </div>
</template>

<script>
// @ is an alias to /src
import TopHero from "@/components/TopHero.vue"
import HomeMain from '@/components/HomeMain.vue'
export default {
  name: 'HomeView',
  components: {
    TopHero,
    HomeMain
  }
}
</script>

<template>
    <section id="hero" class="hero">

    <div class="info d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center" data-aos="fade-up" data-aos-delay="100">
            <h2 data-aos="fade-down">Bienvenue sur <br/><span class="">{{ siteName }}</span></h2>
            <h3 data-aos="fade-down" class="" >SILI-BTP c'est <span class="mov"></span></h3>
            <br/>
            <a data-aos="fade-up" data-aos-delay="200" href="#get-started" class="btn-get-started" style="font-size:2em">Présentation</a>
          </div>
        </div>
      </div>
    </div>

    <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">

      <div class="carousel-item active"></div>
      <div class="carousel-item "></div>
      <div class="carousel-item "></div>
      <div class="carousel-item "></div>
      <div class="carousel-item "></div>



      <a class="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
      </a>

      <a class="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
      </a>

    </div>

  </section>
</template>

<script>
import Typed from 'typed.js'

export default {
    name:"TopHero",
    data(){
      return {
       
      }
    },
    computed:{
      siteName(){
        return this.$store.getters.getSiteName
      },
      carouselImage(){
        return this.$store.getters.getCarouselData
      }
    },
    mounted(){
       var typed = new Typed(".mov",{
        strings: [" la satifaction"," la rigueur"," l'expertise" ," l'exprérience"] ,
        typeSpeed: 100,
        backSpeed: 50,
        loop: true
        })
        return typed
    }
}
</script>

<style lang="css" scoped>
h2>span{
  font-size: 1.5em;
}

h2 > span, h3 >span{
  color:#FD5D14;
}
.carousel-item:nth-child(1){
  background-image: url('https://api.sili-btp.com/img/hero-carousel/hero-carousel-1.jpg');
}
.carousel-item:nth-child(2){
 background-image: url('https://api.sili-btp.com/img/hero-carousel/hero-carousel-2.jpg');
}
.carousel-item:nth-child(3){
 background-image: url('https://api.sili-btp.com/img/hero-carousel/hero-carousel-3.jpg');
}
.carousel-item:nth-child(4){
 background-image: url('https://api.sili-btp.com/img/hero-carousel/hero-carousel-4.jpg');
}
.carousel-item:nth-child(5){
 background-image: url('https://api.sili-btp.com/img/hero-carousel/hero-carousel-5.jpg');
}

.hero  h3{
  color:white;
  font-size: 2.2em;
}
</style>
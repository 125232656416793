import { createStore } from 'vuex'
import {serviceDataMock, expertDataMock, projetDataMock, blogDataMock, heroCarouelData, sellDataMock, siteInfo} from "./mocks"

export default createStore({
  state: {
    siteName:"SILI-BTP SAS",
    serviceData:serviceDataMock,
    expertData: expertDataMock,
    projetData:projetDataMock,
    blogData: blogDataMock,
    heroCarouelData: heroCarouelData,
    sellData : sellDataMock,
    siteInfo: siteInfo,
  },
  getters: {
    getSiteName(state){
      return state.siteName
    },
    getServiceData(state){
      return state.serviceData
    },
    getExpertData(state){
      return state.expertData
    },
    getProjetData(state){
      return state.projetData
    },
    getBlogData(state){
      return state.blogData
    },
    getCarouselData(state){
      return state.heroCarouelData
    },
    getSellData(state){
      return state.sellData
    },
    getSiteInfo(state){
      return state.siteInfo
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

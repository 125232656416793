<template>
  <section :id="id" :class="classes" class="">
          <div class="container" :data-aos="animate" :data-aos-delay="delay">
            <slot> </slot>
         </div>
       </section>
</template>

<script>
export default {
    name:"MixinSectionChild",
    props:{
        classes:{
            type:String,
            required:true,
        },
        animate:{
            type:String,
            required:true
        },
        delay:{
            type:String,
        },
        id:{
            type:String,
            required:true
        }
    }
}
</script>

<style>

</style>
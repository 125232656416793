<template>
    <div class="col-xl-4 col-md-6 " data-aos="fade-up" :data-aos-delay="delay">
        <div class="post-item position-relative h-100 box" :class="activeBox" @mouseenter="Activebox()" @mouseleave="Activebox()">

            <div class="post-img position-relative  overflow-hidden">
                <img :src="imgLink" class="img-fluid" :alt="{title}">
                <span class="post-date">{{date}}</span>
            </div>

            <div class="post-content d-flex flex-column">

            <h3 class="post-title">{{title}}</h3>
                <p>
                 {{ content }}
                </p>
                <hr>
            <div class="text-center">
            <router-link :to="{name:'actualiteDetail', params:{'slug':slug}}" class="readmore mx-auto rounded stretched-link">
                <span>Lire tout</span><i class="bi bi-arrow-right"></i>
            </router-link>
            <!-- <a href="blog-details.html" class="readmore mx-auto rounded stretched-link"><span>Lire tout</span><i class="bi bi-arrow-right"></i></a> -->
            </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: "BlogItemCard",
  data:function(){
    return {
        activeBox:{activeBox:false}
    }
  },
  props:["title", "content", "date", "imgLink", "slug", "delay"],
  methods:{
    Activebox: function(){
        this.activeBox.activeBox= !this.activeBox.activeBox
    }
  }
}
</script>

<style scoped>
a{
text-align: center !important;
min-width: 10rem;
font-size: 1.3em;
}
.activeBox{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12) !important;
}

.box{
    border: rgb(238, 137, 5) solid 2px;
    padding: 0px;
    border-radius: 1.8em;
    overflow: hidden;
}

p{
    max-height: 95px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 1px !important;
}
</style>
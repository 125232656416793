<template>
    <div class="projet team">
      
      <mixin-section-child animate="fade-up" delay="100" classes="projets section-bg " id="projets">
         <div class="section-header">
          <h2>Projets</h2>
        </div>
        <div class="row g-5 portfolio-container mx-auto">
            <projet-card v-for="(projet, index) in projetList" :key="index" :item="projet" :id="index+1" :delay="(index+1)*100" />
        </div>
        <div class="row col-md-4  mx-auto mt-5">
          <!-- <a href="/projets" class="btn-visit align-self-center text-center py-2">Explorer</a> -->
          <router-link class="btn-visit align-self-center text-center py-2" :to="{ name: 'projets'}" > Explorer  nos projets</router-link>
        </div>
      </mixin-section-child>
  </div>
</template>

<script>
import MixinSectionChild from "@/components/Section/MixinSectionChild.vue"
import ProjetCard from "@/components/widgets/ProjetCard.vue"
export default {
    name: "ProjetSecction",
    components:{
      MixinSectionChild,
      ProjetCard
    },
   computed:{
      projetList(){
        const item = this.$store.getters.getProjetData.slice(0, 3)
        return item
      }
    },
}
</script>

<style lang="scss">
  .btn-visit{
    padding: 8px 40px;
    background: var(--color-primary);
    color: #fff;
    border-radius: 50px;
    transition: 0.2s;
    font-size: 1.5em;
    font-weight: bold;
    border: #fff solid 2px;
    &:hover{
      background: #fff;
      color: var(--color-primary);
      border: var(--color-primary) solid 2px;
    }
  }
</style>
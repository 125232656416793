import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: HomeView,
    meta: { transition: 'scale', mode:"out-in"}
  },
  {
    path: '/presentation',
    name: 'presentation',
    
    component: () => import( '../views/PresentationView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/services',
    name: 'services',
    
    component: () => import( '../views/ServiceView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/projets',
    name: 'projets',
    
    component: () => import( '../views/ProjetView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/projets/:projet_id',
    name: 'projetDetail',
    
    component: () => import( '../views/ProjetDetailView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/ventes',
    name: 'ventes',
    
    component: () => import( '../views/SellView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/ventes/:slug',
    name: 'venteDetail',
    
    component: () => import( '../views/SellDetailView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/actualites',
    name: 'actualites',
    
    component: () => import( '../views/BlogView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/actualites/:slug',
    name: 'actualiteDetail',
    component: () => import( '../views/BlogDetailView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/contact',
    name: 'contact',
    
    component: () => import( '../views/ContactView.vue'),
    meta: { transition: 'slide-left', mode:"out-in"}
  },
  {
    path: '/projets',
    name: 'projets',
    
    component: () => import( '../views/ProjetView.vue'),
    meta: { transition: 'slide-right', mode:"out-in"}
  },
  {
    path: '/:catchAll(.*)*',
    name:'notFound',
    component: () => import('../views/ErrorNotFound.vue'),
    meta: { transition: 'scale', mode:"out-out"}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
 <div>
    <a href="#" class="scroll-top d-flex align-items-center justify-content-center">
        <i class="bi bi-arrow-up-short"></i>
    </a>
    <div id="preloader"></div>
  </div>
</template>

<script>


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:"Preloader",
    mounted(){
        const preloader = document.querySelector('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
            preloader.remove();
            });
        }
    },
    unmounted(){
         const preloader = document.querySelector('#preloader');
         if (preloader) {
             preloader.remove();
         }
    },
    updated(){
        const preloader = document.querySelector('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
            preloader.remove();
            });
        }
    }
}
</script>

<style>

</style>
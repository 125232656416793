<template>
  <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Services</h2>
          <h3>Nous proposons les meilleurs service de construction</h3>
        </div>

        <div class="row gy-4">

          <service-card-item v-for="(data, index) in datas" :titre="data.titre" :icon="data.icon" :description="data.description"    :key="index"  />
          <!-- End Service Item -->

        </div>
       <div class="row col-md-4  mx-auto mt-5">
          <!-- <a href="/projets" class="btn-visit align-self-center text-center py-2">Explorer</a> -->
          <router-link class="btn-visit align-self-center text-center py-2" :to="{ name: 'services'}" > parcourir</router-link>
        </div>
      </div>
    </section>
</template>

<script>
import ServiceCardItem from "@/components/widgets/ServiceCardItem.vue"
export default {
    name:"ServiceSection",
    components:{
      ServiceCardItem
    },
    computed: {
      datas(){
        return this.$store.getters.getServiceData.slice(0, 3)
      }
    },

}
</script>

<style>

</style>
<template>
  <div class="services">
     <section id="constructions" class="constructions">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h2>Nos Ventes et Locations</h2>
        </div>

        <div class="row gy-4">
          
          <!-- <sell-card /> -->
          <!-- End Card Item -->

        </div>

      </div>
    </section>
  </div>
</template>

<script>
// import SellCard from "@/components/widgets/SellCard.vue"
export default {
    name:"SellSection",
    components:{
    }

}
</script>

<style>

</style>
<template>
     <section id="blog" class="blog section-bg">
      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="section-header">
          <h2>Actualités</h2>
        </div>
        <div class="row gy-4 posts-list">

          <blog-item-card v-for="(item, index) in dataList" 
          :key="index" 
          :title="item.title" 
          :content="item.content" 
          :date="item.date"
          :imgLink="item.img"
          :slug="item.slug" 
           :delay="(index+1)*100" />
          <!-- End post list item -->
        </div><!-- End blog posts list -->

        <!-- End blog pagination -->

      </div>
    </section>
</template>

<script>
import BlogItemCard from "@/components/widgets/BlogItemCard.vue"
export default {
    name:"BlogSection",
    components:{
      BlogItemCard,
    } ,
    computed:{
      dataList(){
        return this.$store.getters.getBlogData?.slice(0,3)
      }
    }
}
</script>

<style>

</style>